html {
  scroll-behavior: smooth !important;
  font-family: "Lato", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
background: linear-gradient(to bottom,
      rgba(15, 202, 86, 0.5),
      rgba(8, 169, 69, 0.5)) !important;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: linear-gradient(to top, #0FCA56, #08A945) !important;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.SRLElementWrapper {
  transform: none !important;
  opacity: 1 !important;
}
